/* global window */
import React, { Component, createRef } from 'react';
import css from './Listing.scss';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import Button from 'Components/Button/Button';
import classNames from 'classnames';
import LoadMoreObserver from 'Components/LoadMoreObserver/LoadMoreObserver';
import { HEADER_OFFSET } from 'Constants/items';
import { withConfig } from 'HOCs/withConfig/withConfig';

export class Listing extends Component {
    static getDerivedStateFromProps(props, state) {
        if (state.showLoadMoreState !== props.showLoadMore) {
            return { ...state, showLoadMoreState: (props.showLoadMore && !props.similarAdsData?.length) || (props.showLoadMore && !!props.similarAdsCount) };
        }
        return state;
    }

    static propTypes = {
        items: PropTypes.array,
        itemsLength: PropTypes.number,
        onLoadNextPage: PropTypes.func,
        isFetching: PropTypes.bool,
        className: PropTypes.string,
        header: PropTypes.element,
        page: PropTypes.number,
        showLoadMore: PropTypes.bool,
        similarAdsData: PropTypes.array,
        similarAdsCount: PropTypes.number,
        renderPagination: PropTypes.func,
        children: PropTypes.node,
        renderBanners: PropTypes.func,
        adIdsToUpdate: PropTypes.array,
        autoScroll: PropTypes.bool,
        isDealerShowroomPage: PropTypes.bool,
        fromPage: PropTypes.string
    };

    static defaultProps = {
        items: [],
        itemsLength: 0,
        renderPagination: null,
        similarAdsCount: 0,
        similarAdsData: [],
        onLoadNextPage: () => {},
        isFetching: false,
        className: '',
        page: 1,
        renderBanners: () => {},
        adIdsToUpdate: [],
        autoScroll: false,
        fromPage: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            showLoadMoreState: (props.showLoadMore && !props.similarAdsData?.length) || (props.showLoadMore && !!props.similarAdsCount)
        };
        this.loadMoreRef = createRef();
        this.scrollPos = null;
        this.isLoadMoreClicked = false;
    }

    componentDidUpdate(prevProps, __, snapshot) {
        if (snapshot !== null) {
            window.scrollTo({
                top: snapshot - HEADER_OFFSET,
                left: 0
            });
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (this.props.isFetching && this.isLoadMoreClicked && prevProps.itemsLength !== this.props.itemsLength) {
            const loadMoreBtn = this.loadMoreRef.current;

            this.scrollPos = loadMoreBtn?.offsetTop;
        }
        else if (!this.props.isFetching && this.isLoadMoreClicked && prevProps.itemsLength < this.props.itemsLength && this.scrollPos !== null) {
            const scrollPos = this.scrollPos;

            this.scrollPos = null;
            this.isLoadMoreClicked = false;

            return scrollPos;
        }
        return null;
    }

    loadMore = () => {
        const { isFetching, onLoadNextPage } = this.props;

        this.isLoadMoreClicked = true;
        return isFetching ? null : onLoadNextPage();
    };

    renderLoadMore = () => {
        const { items, isFetching, page, showLoadMore, isDealerShowroomPage, fromPage } = this.props;
        const shouldShow = (typeof showLoadMore === 'undefined' && isFinite(page))
            ? (items.total > (Number(page) * items.length))
            : this.state.showLoadMoreState;

        return shouldShow && (
            <div ref={ this.loadMoreRef } className={ classNames(css.loadMore, { [css.loading]: isFetching }) }>
                <Button
                    type="secondaryBtn"
                    data-aut-id="btnLoadMore"
                    onClick={ !(fromPage === 'dealerPreview') && this.loadMore }
                    className={ classNames({ [css.customLoadMoreBtn]: isDealerShowroomPage }) }
                >
                    <Translation id="loadMore" />
                    {/* {isDealerShowroomPage ? <Translation id="viewAll" /> : <Translation id="loadMore" />} */}
                </Button>
            </div>
        );
    }

    renderAutoScroll = () => {
        const { autoScroll, isFetching, page, onLoadNextPage } = this.props;
        const shouldAddObserver = autoScroll && this.state.showLoadMoreState && !isFetching;

        return shouldAddObserver ? <LoadMoreObserver key={ `loadMore-${page}` } loadMore={ onLoadNextPage } /> : null;
    }

    render() {
        const { className, header, renderBanners, adIdsToUpdate, renderPagination, autoScroll } = this.props;

        return (
            <div className={ className }>
                { header }
                <div>
                    { React.Children.map(this.props.children, c => React.cloneElement(c, {
                        loadMoreBtn: !autoScroll && !renderPagination ? this.renderLoadMore() : null
                    }))}
                </div>
                { autoScroll ? this.renderAutoScroll() : (renderPagination && renderPagination() || null)}
                { renderBanners('bottom', adIdsToUpdate) }
            </div>
        );
    }
}

export default withConfig('marketConfig')(Listing);
