import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import css from './AdvertisingComponent.scss';
import classNames from 'classnames';
import { buildObjectFromURL } from 'Helpers/url';
import { isEmpty } from 'Helpers/objects';
import { viewTypeTrackDictionary } from 'Selectors/viewTypes';
import isEqual from 'lodash/isEqual';
import { itemsPageIndexSelector } from 'Selectors/items';
import { PLATFORM, BAXTER_PLATFORM } from 'Constants/device.APP_TARGET';
import withConfig from 'HOCs/withConfig/withConfig';
import { ALL_CATEGORIES, DETAILS_PAGE, DETAILS_PAGE_VIEW, LISTING_PAGE_VIEW, RESULTS_PAGE, SEARCH_PAGE_VIEW, ANDBEYOND, ANDBEYOND_MOBILE_ID, ANDBEYOND_DESKTOP_ID } from 'Constants/baxter';
import { isMobile } from 'Helpers/devices';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
export class AdvertisingComponent extends React.Component {
    static propTypes = {
        slot: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        category: PropTypes.object,
        rootClassName: PropTypes.string,
        className: PropTypes.string,
        sticky: PropTypes.bool,
        item: PropTypes.object,
        location: PropTypes.object,
        config: PropTypes.object.isRequired,
        selectedCategory: PropTypes.object,
        selectedLocation: PropTypes.object,
        pageIndex: PropTypes.string,
        adIdsToUpdate: PropTypes.array,
        is_business: PropTypes.bool,
        business_name: PropTypes.string,
        seller_id: PropTypes.string,
        dynamicAdId: PropTypes.string
    };

    static defaultProps = {
        category: {},
        rootClassName: '',
        className: '',
        sticky: false,
        item: {},
        location: {},
        selectedCategory: {},
        selectedLocation: {},
        pageIndex: '',
        adIdsToUpdate: [],
        is_business: false,
        business_name: '',
        seller_name: '',
        dynamicAdId: ''
    };

    constructor() {
        super();
        this.state = {
            validSlots: ['top', 'top-sticky'],
            validPages: ['home', 'results', 'details']
        };
    }

    componentDidMount() {
        this.setToUpdate(true);
    }

    componentDidUpdate(prevProps) {
        const current = {
            pathname: this.props.location.pathname.replace('/gallery', ''),
            query: this.props.location.query,
            location: this.props.selectedLocation.id
        };

        const prev = {
            pathname: prevProps.location.pathname.replace('/gallery', ''),
            query: prevProps.location.query,
            location: prevProps.selectedLocation.id
        };

        if (this.props.adIdsToUpdate.length > 0) {
            current.pageIndex = this.props.pageIndex;
            prev.pageIndex = prevProps.pageIndex;
        }

        if (this.props.item && prevProps.item) {
            current.item = this.props.item;
            prev.item = prevProps.item;
        }

        if (!isEqual(current, prev)) {
            this.setToUpdate(true);
        }
    }

    componentWillUnmount() {
        if (typeof Baxter !== 'undefined') {
            Baxter.unset(this.getUnitId()); // eslint-disable-line no-undef
        }
    }

    getRootClassName = (rootClassName = '', slot, page) => {
        const adFixedHeightStyle = isMobile ? css.adFixedHeightMobile : css.adFixedHeightWeb;
        const adFixedHeightClass = this.state.validSlots.includes(slot) ? adFixedHeightStyle : '';

        const isHomeAndNotTop = isMobile && this.state.validPages.includes(page) && slot !== 'top';
        const customClass = isHomeAndNotTop ? css.adFixedHeightWeb : adFixedHeightClass;

        return classNames(
            css.bannersContainer,
            rootClassName,
            {
                [css.stickyContainer]: this.props.sticky,
                [customClass]: !this.props.sticky
            }
        );
    };

    getClassName = (page, slot, className = '') => {
        const validHeightClass = (slot === 'bottom' || slot.includes('middle')) && isMobile ? css.adClassWrapper : '';

        return classNames(css[`${page}${slot}Banner`], validHeightClass, className);
    }

    handleOnPlatformChange = platform => this.setState({ platform });

    shouldSetUnit = input => {
        const { adIdsToUpdate } = this.props;

        if (input && input.id && this.toUpdate() === true) {
            return !(adIdsToUpdate.length > 0 && adIdsToUpdate.indexOf(input.id) < 0);
        }
        return false;
    };

    setUnit = input => {
        if (this.shouldSetUnit(input)) {
            if (typeof Baxter === 'undefined') {
                const timeout = 500;

                setTimeout(() => this.setUnit(input), timeout);
            }
            else {
                const state = buildObjectFromURL(this.props.location.search).params || null;
                const filters = state ? this.mapFilters(state.filters) : null;
                const { category, item, pageIndex, adIdsToUpdate, is_business, seller_id, business_name, config, page, slot } = this.props;
                const itemParameters = {};
                const miscParameters = {};
                const visualisationType = config.get('visualizationType', PLATFORM);
                const resultsPageView = isEmpty(this.props.selectedCategory) ? SEARCH_PAGE_VIEW : LISTING_PAGE_VIEW;
                const detailsPageView = (page === DETAILS_PAGE) ? DETAILS_PAGE_VIEW : '';
                const pageView = (page === RESULTS_PAGE) ? resultsPageView : detailsPageView;
                const categoryType = (pageView === SEARCH_PAGE_VIEW ? ALL_CATEGORIES : category?.L1?.key);

                if (item && item.parameters) {
                    item.parameters.forEach(param => (itemParameters[param.key] = param));
                    itemParameters['is_featured'] = {
                        key: 'is_featured',
                        value_name: `${!!(item.package || (item.monetizationInfo && !isEmpty(item.monetizationInfo)
                            && item.monetizationInfo.currentProduct))}`
                    };
                }

                miscParameters['page_index'] = { key: 'page_index', value_name: adIdsToUpdate.length > 0 ? pageIndex : '0' };

                let dynamicAdId = this.props.dynamicAdId;

                if (slot === ANDBEYOND) {
                    dynamicAdId = isMobile ? ANDBEYOND_MOBILE_ID : ANDBEYOND_DESKTOP_ID;
                }

                const params = {
                    ...this.props,
                    filters,
                    category,
                    item,
                    visualisationType: viewTypeTrackDictionary[visualisationType] || null,
                    itemParameters,
                    miscParameters,
                    is_business,
                    seller_id,
                    business_name,
                    device: BAXTER_PLATFORM,
                    pageView,
                    categoryType,
                    dynamicAdId
                };

                Baxter.set(input.id, params); // eslint-disable-line no-undef
                this.setToUpdate(false);
            }
        }
    };

    mapFilters = (applied, category, mapped) => {
        category = category || this.props.selectedCategory;
        mapped = mapped || {};

        if (category && typeof category === 'object' && applied && typeof applied === 'object') {
            Object.keys(applied).forEach(appliedKey => {
                if (Object.prototype.hasOwnProperty.call(applied, appliedKey)) {
                    const appliedFilter = applied[appliedKey];

                    if (typeof category.params === 'object') {
                        const categoryParam = category.params.find(obj => obj.key === appliedKey) || {};
                        const categoryParams = categoryParam.values;

                        if (typeof categoryParams === 'object') {
                            if (typeof appliedFilter === 'string') {
                                const value = categoryParams.find(obj => obj.key === appliedFilter) || {};

                                mapped[appliedKey] = value || null;
                            }
                            else if (Array.isArray(appliedFilter)) {
                                appliedFilter.forEach(appliedValue => {
                                    mapped[appliedKey] = mapped[appliedKey] || [];
                                    const value = categoryParams.find(obj => obj.key === appliedValue) || {};

                                    if (value.name) {
                                        mapped[appliedKey].push(value);
                                    }
                                });
                            }
                            if (mapped[appliedKey] && Array.isArray(mapped[appliedKey].params)) {
                                return this.mapFilters(applied, mapped[appliedKey], mapped);
                            }
                        }
                    }

                    mapped[appliedKey] = mapped[appliedKey] || appliedFilter;
                }

                return null;
            });
        }

        return mapped;
    };

    getUnitId = () => {
        return `baxter-ads-${this.props.page}-${this.props.slot}`;
    };

    toUpdate = () => {
        return this.state && this.state.toUpdate === true;
    };

    setToUpdate = toUpdate => {
        this.setState({ toUpdate });
    };

    getMyAdsComponent(displayLogo) {
        const staticAsseturl = this.props.config.get('staticAssets');

        if (displayLogo) {
            const rocketImageStyle = isMobile ? css.rocketImageMobile : css.rocketImageDesktop;

            return (
                <div className={ css.logoIconWrapper }>
                    <ImageWrapper
                        fileName={ `${staticAsseturl}/external/base/img/rocket-image/rocket_placeholder.svg` }
                        className={ rocketImageStyle }
                    />
                </div>
            );
        }

        return null;
    }

    render() {
        const { page, slot, className, rootClassName } = this.props;
        const adClass = this.getClassName(page, slot, className);
        const isAndBeyond = (slot === ANDBEYOND);
        const adRootClass = this.getRootClassName(rootClassName, slot, page);

        const advertisingEnabled = this.props.config.get('advertising').enabled;
        const shouldDisplayAdText = (this.state.validPages.includes(page) && isMobile) || this.state.validSlots.includes(slot);

        return (
            advertisingEnabled
                ? <div className={ !isAndBeyond && adRootClass } key={ `${page}-${slot}` } >
                    {shouldDisplayAdText && !isAndBeyond && this.getMyAdsComponent(this.state.toUpdate)}
                    <div
                        id={ this.getUnitId() }
                        ref={ input => this.setUnit(input) }
                        className={ adClass }
                    />
                </div>
                : null
        );
    }
}

export const mapStateToProps = (state, props) => ({
    selectedCategory: state.categories.elements[props.params.categoryID],
    selectedLocation: state.locations.selectedLocation,
    item: props.params.id ? state.items.elements[props.params.id] : null,
    pageIndex: itemsPageIndexSelector(state)
});

export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps)
)(AdvertisingComponent);
